import {
  DOG_GROOMING_SERVICE_ID,
  DOG_TRAINING_SERVICE_ID,
  DOG_WALKING_SERVICE_ID,
  HOUSE_VISITING_SERVICE_ID,
  PET_DAY_CARE_SERVICE_ID,
  PET_HOSTING_SERVICE_ID,
  PET_SITTING_SERVICE_ID,
} from './app';

export const DEFAULT_PAGE_SIZE = 20;

export const searchServiceSlugMapping: { [key: string]: number } = {
  petsitters: PET_HOSTING_SERVICE_ID,
  petminding: PET_SITTING_SERVICE_ID,
  doggydaycare: PET_DAY_CARE_SERVICE_ID,
  housevisits: HOUSE_VISITING_SERVICE_ID,
  dogwalkers: DOG_WALKING_SERVICE_ID,
  doggroomers: DOG_GROOMING_SERVICE_ID,
  dogtrainers: DOG_TRAINING_SERVICE_ID,
};

export const DEFAULT_SERVICE_SLUG = 'petsitters';
export const DEFAULT_SEARCH_PAGE_SLUG = `/${DEFAULT_SERVICE_SLUG}/sydney-nsw`;

export const EMPTY_SERVICE_TYPE_ID = 0;
export const EMPTY_SERVICE = { type: '', quantity: 0 };

export const SERVICE_TYPE_BY_NAME_MAPPING: {
  [key: string]: { id: number; serviceJob: string; serviceName: string; serviceTitle: string };
} = {
  petsitters: {
    id: PET_HOSTING_SERVICE_ID,
    serviceName: 'Pet Sitting',
    serviceTitle: 'Pet Sitting & Home Dog Boarding',
    serviceJob: 'Pet Sitters',
  },
  petminding: {
    id: PET_SITTING_SERVICE_ID,
    serviceName: 'House Sitting & Pet Minding',
    serviceTitle: 'House Sitting & Pet Minding',
    serviceJob: 'Pet Sitters',
  },
  doggydaycare: {
    id: PET_DAY_CARE_SERVICE_ID,
    serviceName: 'Pet Day Care',
    serviceTitle: 'Doggy Daycare',
    serviceJob: 'Dog Sitters',
  },
  housevisits: {
    id: HOUSE_VISITING_SERVICE_ID,
    serviceName: 'House Visiting',
    serviceTitle: 'House Visits',
    serviceJob: 'Pet Minders',
  },
  dogwalkers: {
    id: DOG_WALKING_SERVICE_ID,
    serviceName: 'Dog Walking',
    serviceTitle: 'Dog Walking',
    serviceJob: 'Dog Walkers',
  },
  doggroomers: {
    id: DOG_GROOMING_SERVICE_ID,
    serviceName: 'Dog Grooming',
    serviceTitle: 'Dog Grooming',
    serviceJob: 'Dog Groomers',
  },
  dogtrainers: {
    id: DOG_TRAINING_SERVICE_ID,
    serviceName: 'Dog Training',
    serviceTitle: 'Dog Training',
    serviceJob: 'Dog Trainers',
  },
  'pet-groomers': {
    id: DOG_GROOMING_SERVICE_ID,
    serviceName: 'Pet Grooming',
    serviceTitle: 'Pet Grooming',
    serviceJob: 'Pet Groomers',
  },
  'pet-boarding': {
    id: PET_HOSTING_SERVICE_ID,
    serviceName: 'Pet Boarding',
    serviceTitle: 'Pet Boarding',
    serviceJob: 'Pet Boarders',
  },
  'dog-boarding': {
    id: PET_HOSTING_SERVICE_ID,
    serviceName: 'Dog Boarding',
    serviceTitle: 'Dog Boarding',
    serviceJob: 'Dog Boarders',
  },
  'dog-washing': {
    id: DOG_GROOMING_SERVICE_ID,
    serviceName: 'Dog Washing',
    serviceTitle: 'Dog Washing',
    serviceJob: 'Dog Washers',
  },
  'dog-sitting': {
    id: PET_HOSTING_SERVICE_ID,
    serviceName: 'Dog Sitting',
    serviceTitle: 'Dog Sitting',
    serviceJob: 'Dog Sitters',
  },
  'dog-minding': {
    id: PET_HOSTING_SERVICE_ID,
    serviceName: 'Dog Minding',
    serviceTitle: 'Dog Minding',
    serviceJob: 'Dog Minders',
  },
  'cat-boarding': {
    id: PET_SITTING_SERVICE_ID,
    serviceName: 'Cat Boarding',
    serviceTitle: 'Cat Boarding',
    serviceJob: 'Cat Boarders',
  },
  'cat-sitting': {
    id: HOUSE_VISITING_SERVICE_ID,
    serviceName: 'Cat Sitting',
    serviceTitle: 'Cat Sitting',
    serviceJob: 'Cat Sitters',
  },
  'rabbit-boarding': {
    id: HOUSE_VISITING_SERVICE_ID,
    serviceName: 'Rabbit Boarding',
    serviceTitle: 'Rabbit Boarding',
    serviceJob: 'Rabbit Boarders',
  },
  'rabbit-sitting': {
    id: HOUSE_VISITING_SERVICE_ID,
    serviceName: 'Rabbit Sitting',
    serviceTitle: 'Rabbit Sitting',
    serviceJob: 'Rabbit Sitters',
  },
  'puppy-sitting': {
    id: HOUSE_VISITING_SERVICE_ID,
    serviceName: 'Puppy Sitting',
    serviceTitle: 'Puppy Sitting',
    serviceJob: 'Puppy Sitters',
  },
  'bird-sitting': {
    id: HOUSE_VISITING_SERVICE_ID,
    serviceName: 'Bird Sitting',
    serviceTitle: 'Bird Sitting',
    serviceJob: 'Bird Sitters',
  },
  'bird-boarding': {
    id: HOUSE_VISITING_SERVICE_ID,
    serviceName: 'Bird Boarding',
    serviceTitle: 'Bird Boarding',
    serviceJob: 'Bird Boarders',
  },
  'puppy-daycare': {
    id: PET_DAY_CARE_SERVICE_ID,
    serviceName: 'Puppy Daycare',
    serviceTitle: 'Puppy Daycare',
    serviceJob: 'Puppy Sitters',
  },
  'dog-feeding': {
    id: PET_DAY_CARE_SERVICE_ID,
    serviceName: 'Dog Feeding',
    serviceTitle: 'Dog Feeding',
    serviceJob: 'Dog Feeders',
  },
  'cat-feeding': {
    id: PET_DAY_CARE_SERVICE_ID,
    serviceName: 'Cat Feeding',
    serviceTitle: 'Cat Feeding',
    serviceJob: 'Cat Feeders',
  },
};

export const DEFAULT_SERVICE_NAME = SERVICE_TYPE_BY_NAME_MAPPING.petsitters.serviceName;
export const DEFAULT_SERVICE_JOB = SERVICE_TYPE_BY_NAME_MAPPING.petsitters.serviceJob;
export const DEFAULT_SERVICE_TITLE = SERVICE_TYPE_BY_NAME_MAPPING.petsitters.serviceTitle;

// Sort Filters
export const SORT_FILTER_RECOMMENDED_ID = 'performance';
export const SORT_FILTER_LOCATION_ID = 'location';

export const SORT_FILTER_OPTIONS = [
  {
    label: 'Recommended',
    value: SORT_FILTER_RECOMMENDED_ID,
  },
  {
    label: 'Location',
    value: SORT_FILTER_LOCATION_ID,
  },
];

export const DEFAULT_SORT_FILTER = {
  label: 'Recommended',
  value: SORT_FILTER_RECOMMENDED_ID,
};

// Advanced Filters
export const DEFAULT_PRICE_RANGE = ['0', '300'];

export const SITTERS_HOME_OPTIONS_HOUSE_ID = 'house';
export const SITTERS_HOME_OPTIONS_APARTMENT_ID = 'apartment';
export const SITTERS_HOME_OPTIONS_FENCED_ID = 'fenced';

export const ALLOWED_SITTERS_HOME_OPTIONS = [
  SITTERS_HOME_OPTIONS_APARTMENT_ID,
  SITTERS_HOME_OPTIONS_FENCED_ID,
  SITTERS_HOME_OPTIONS_HOUSE_ID,
];

export const SITTERS_HOME_OPTIONS = [
  {
    label: 'House',
    value: SITTERS_HOME_OPTIONS_HOUSE_ID,
  },
  {
    label: 'Apartment',
    value: SITTERS_HOME_OPTIONS_APARTMENT_ID,
  },
  {
    label: 'Fenced backyard',
    value: SITTERS_HOME_OPTIONS_FENCED_ID,
  },
];

export const PETS_KIDS_AT_HOME_OPTIONS_NO_DOG_ID = 'no_dog';
export const PETS_KIDS_AT_HOME_OPTIONS_NO_CAT_ID = 'no_cat';
export const PETS_KIDS_AT_HOME_OPTIONS_NO_CHILDREN_ID = 'no_children';

export const ALLOWED_PETS_KIDS_AT_HOME_OPTIONS = [
  PETS_KIDS_AT_HOME_OPTIONS_NO_DOG_ID,
  PETS_KIDS_AT_HOME_OPTIONS_NO_CAT_ID,
  PETS_KIDS_AT_HOME_OPTIONS_NO_CHILDREN_ID,
];

export const PETS_KIDS_AT_HOME_OPTIONS = [
  {
    label: 'Doesn’t own a dog',
    value: PETS_KIDS_AT_HOME_OPTIONS_NO_DOG_ID,
  },
  {
    label: 'Doesn’t own a cat',
    value: PETS_KIDS_AT_HOME_OPTIONS_NO_CAT_ID,
  },
  {
    label: 'Doesn’t have children',
    value: PETS_KIDS_AT_HOME_OPTIONS_NO_CHILDREN_ID,
  },
];

export const ABOUT_THE_SITTER_OPTIONS_POLICY_CHECK_ID = 'police_check';
export const ABOUT_THE_SITTER_OPTIONS_ADMIN_MEDICINE_ID = 'administer_medicine';
export const ABOUT_THE_SITTER_OPTIONS_FAST_RESPONSE_ID = 'fast_response';
export const ABOUT_THE_SITTER_OPTIONS_PICK_UP_ID = 'pick_up';

export const ALLOWED_ABOUT_THE_SITTER_OPTIONS = [
  ABOUT_THE_SITTER_OPTIONS_POLICY_CHECK_ID,
  ABOUT_THE_SITTER_OPTIONS_ADMIN_MEDICINE_ID,
  ABOUT_THE_SITTER_OPTIONS_FAST_RESPONSE_ID,
  ABOUT_THE_SITTER_OPTIONS_PICK_UP_ID,
];

export const ABOUT_THE_SITTER_OPTIONS = [
  {
    label: 'Police check',
    value: ABOUT_THE_SITTER_OPTIONS_POLICY_CHECK_ID,
  },
  {
    label: 'Administers medication',
    value: ABOUT_THE_SITTER_OPTIONS_ADMIN_MEDICINE_ID,
  },
  // TODO (WEB-1096): need to investigate the usage of this filter
  // {
  //   label: 'Fast response',
  //   value: ABOUT_THE_SITTER_OPTIONS_FAST_RESPONSE_ID,
  // },
  {
    label: 'Pick-up and drop-off',
    value: ABOUT_THE_SITTER_OPTIONS_PICK_UP_ID,
  },
];

// not search related query parameters
// that should be tracked in URL as well
export const SEARCH_POSITION_QUERY_PARAMETER = 'searchPosition';
export const SEARCH_ALGORITHM_QUERY_PARAMETER = 'searchAlgorithm';

export const EXTRA_QUERY_PARAMETERS = [
  SEARCH_POSITION_QUERY_PARAMETER,
  SEARCH_ALGORITHM_QUERY_PARAMETER,
];
