import { useContext } from 'react';

import { SplitIOContext } from './context';

import type { Context } from './context';

type Props = Context & {
  isSplitIoLoading: boolean;
};

export const useSplitIO = (): Props => {
  const { isSplitIoLoading, getTreatments, treatments } = useContext(SplitIOContext);

  return {
    getTreatments,
    treatments,
    isSplitIoLoading,
  };
};
