import { SplitFactory } from '@splitsoftware/splitio-browserjs';
import { useEffect, useState } from 'react';

import {
  HOUSE_VISITS_HIGHLIGHTING_SPLIT_ID,
  AREA10_CMAX_SEO_CONTENT_ON_SEARCH_FOR_SITTER_PAGE_SPLIT_ID,
  AREA10_CMAX_SEO_CONTENT_ON_SITTER_PROFILE_PAGE_SPLIT_ID,
  PRICE_CONSISTENCY_SPLIT_ID,
  SPLIT_IO_INTERVAL_TO_INIT,
  TWO_FACTOR_AUTHENTICATION_SOCIAL_LOGINS_ID,
} from './constants';
import { SplitIOContext } from './context';
import { getSegmentAnonymousId } from '../analytics/analytics';

import type SplitIO from '@splitsoftware/splitio-browserjs/types/splitio';
import type { ReactElement, ReactNode } from 'react';

const NEXT_PUBLIC_SPLITIO_AUTH_KEY = process.env.NEXT_PUBLIC_SPLITIO_AUTH_KEY;

type Props = {
  children: ReactNode;
};

export const SplitIOProvider = ({ children }: Props): ReactElement => {
  const [treatments, setTreatments] = useState<SplitIO.Treatments>({});
  const [segmentAnonymousId, setSegmentAnonymousId] = useState<string | undefined>();
  const [isSplitIoLoading, setIsSplitIoLoading] = useState<boolean>(true);

  const initSplitIo = async (segmentId: string): Promise<void> => {
    const factory: SplitIO.ISDK = SplitFactory({
      core: {
        authorizationKey: NEXT_PUBLIC_SPLITIO_AUTH_KEY || '',
        key: segmentId,
      },
    });

    const splitIoClient: SplitIO.IClient = factory.client();

    splitIoClient.on(splitIoClient.Event.SDK_READY, () => {
      const splitIoTreatments: SplitIO.Treatments = splitIoClient.getTreatments([
        HOUSE_VISITS_HIGHLIGHTING_SPLIT_ID,
        AREA10_CMAX_SEO_CONTENT_ON_SEARCH_FOR_SITTER_PAGE_SPLIT_ID,
        AREA10_CMAX_SEO_CONTENT_ON_SITTER_PROFILE_PAGE_SPLIT_ID,
        PRICE_CONSISTENCY_SPLIT_ID,
        TWO_FACTOR_AUTHENTICATION_SOCIAL_LOGINS_ID,
      ]);

      setTreatments(splitIoTreatments);
    });
  };

  useEffect(() => {
    if (!window.analytics || !NEXT_PUBLIC_SPLITIO_AUTH_KEY) {
      return;
    }

    // To init SplitIO we use AnonymousId by Segment analytics
    // Current approach checks analytics object for it
    // with 1 sec interval and clears interval on success
    const interval = setInterval(() => {
      const segmentId = getSegmentAnonymousId();

      if (segmentId) {
        setSegmentAnonymousId(segmentId);
        clearInterval(interval);
      }
    }, SPLIT_IO_INTERVAL_TO_INIT);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (segmentAnonymousId) {
      initSplitIo(segmentAnonymousId).then(() => setIsSplitIoLoading(false));
    }
  }, [segmentAnonymousId]);

  const getTreatments = async (): Promise<SplitIO.Treatments> =>
    new Promise((resolve) => {
      if (!isSplitIoLoading) {
        resolve(treatments);
      } else {
        const checkInterval = setInterval(() => {
          if (!isSplitIoLoading) {
            clearInterval(checkInterval);
            resolve(treatments);
          }
        }, 100);
      }
    });

  return (
    <SplitIOContext.Provider value={{ getTreatments, isSplitIoLoading, treatments }}>
      {children}
    </SplitIOContext.Provider>
  );
};
