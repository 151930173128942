import React, { useEffect, useState } from 'react';

import styles from './Brainfish.module.css';

import type { ReactElement } from 'react';

const FRESHCHAT_WIDGET_STATUS = 'freshchatWidgetStatus';
const FRESHCHAT_WIDGET_STATUS_ENABLED = 'enabled';
const FRESHCHAT_API_KEY = process.env.NEXT_PUBLIC_FRESHCHAT_API_KEY;

export const BrainfishButton = (): ReactElement | null => {
  const [isBrainfishInitialized, setIsBrainfishInitialized] = useState(false);
  const [isBrainfishInitializing, setIsBrainfishInitializing] = useState(false);
  const [shouldShowBrainfishButtonOnPage, setShouldShowBrainfishButtonOnPage] = useState(true);
  const [loadFreshChatWidget, setLoadFreshChatWidget] = useState({ load: false, open: false });

  useEffect(() => {
    if (!loadFreshChatWidget.load) {
      return;
    }

    const script = document.createElement('script');
    script.src = '//au.fw-cdn.com/20719893/194684.js';
    script.setAttribute('chat', 'true');

    if (loadFreshChatWidget.open) {
      script.onload = function (): void {
        const interval = setInterval(async function () {
          if (window.fcWidget && window.fcWidget.init && FRESHCHAT_API_KEY) {
            window.fcWidget.init({
              token: FRESHCHAT_API_KEY,
            });
            clearInterval(interval);

            window.fcWidget.open();
          }
        }, 500);
      };
    }

    document.body.appendChild(script);
  }, [loadFreshChatWidget]);

  useEffect(() => {
    // If a user have opened Freshchat widget at any page
    // we prevent Brainfish button and widget from rendering
    const freshchatWidgetStatus = localStorage.getItem(FRESHCHAT_WIDGET_STATUS);

    if (freshchatWidgetStatus !== FRESHCHAT_WIDGET_STATUS_ENABLED) {
      return;
    }

    setShouldShowBrainfishButtonOnPage(false);

    // load the Freshchat snippet but don't activate it
    setLoadFreshChatWidget({ load: true, open: false });
  }, []);

  const onWidgetScriptLoaded = async (): Promise<void> => {
    if (window.Brainfish && !isBrainfishInitialized) {
      await window.Brainfish.Widgets.init({
        widgetKey: 'bf_search_widget_KAJ3N1TUbAGHo4v6NX0AsCBRsPhZiy9VQdMszX',
        overrides: {
          nextBestActions: [
            {
              label: 'Chat with Support',
              type: 'function',
              value: (): void => {
                // disable showing widget if flag from localStorage is disabled
                setShouldShowBrainfishButtonOnPage(false); // hide button on the current page
                localStorage.setItem(FRESHCHAT_WIDGET_STATUS, FRESHCHAT_WIDGET_STATUS_ENABLED);
                window.Brainfish.HelpWidget.close(); // close Brainfish widget

                // load the Freshchat snippet and activate it
                setLoadFreshChatWidget({ load: true, open: true });
              },
              icon: 'ChatsCircle',
            },
            {
              label: 'Submit a Ticket',
              type: 'link',
              value: 'https://madpaws.freshdesk.com/support/tickets/new',
              icon: 'Ticket',
            },
          ],
        },
      });

      setIsBrainfishInitialized(true);
    }

    await window.Brainfish.HelpWidget.open();
    setIsBrainfishInitializing(false);

    return;
  };

  const handleClick = async (): Promise<void> => {
    // Open widget in case it was already initialized
    if (window.Brainfish?.HelpWidget && isBrainfishInitialized) {
      await window.Brainfish.HelpWidget.open();

      return;
    }

    // This flag is to disable button and prevent script tag
    // from adding itself to DOM many times
    setIsBrainfishInitializing(true);

    const script = document.createElement('script');
    script.type = 'module';
    script.src = 'https://cdn.jsdelivr.net/npm/@brainfish-ai/web-widget@latest/dist/web.js';
    script.addEventListener('load', onWidgetScriptLoaded);
    document.head.appendChild(script);
  };

  return shouldShowBrainfishButtonOnPage ? (
    <div className={styles.root}>
      <button
        aria-label="Launch Brainfish live chat widget"
        className="brainfish-trigger-button"
        disabled={isBrainfishInitializing}
        onClick={handleClick}
        type="button"
      >
        <svg aria-hidden="true" height="20" viewBox="0 0 20 20" width="20">
          <g id="Layer_4">
            <path d="M11,12.3V13c0,0-1.8,0-2,0v-0.6c0-0.6,0.1-1.4,0.8-2.1c0.7-0.7,1.6-1.2,1.6-2.1c0-0.9-0.7-1.4-1.4-1.4 c-1.3,0-1.4,1.4-1.5,1.7H6.6C6.6,7.1,7.2,5,10,5c2.4,0,3.4,1.6,3.4,3C13.4,10.4,11,10.8,11,12.3z" />
            <circle cx="10" cy="15" r="1" />
            <path d="M10,2c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S5.6,2,10,2 M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0 L10,0z" />
          </g>
        </svg>
        <span aria-hidden="true">Help</span>
      </button>
    </div>
  ) : null;
};
