export const SEARCHED_FOR_SITTER_EVENT_NAME = 'Searched For Sitter';
export const UPDATE_SITTER_SEARCH_EVENT_NAME = 'Update Sitter Search';
export const UPDATE_FILTER_LOCATION_EVENT_NAME = 'Search Sitter Filter Location';
export const UPDATE_FILTER_SERVICE_EVENT_NAME = 'Search Sitter Filter Service';
export const UPDATE_FILTER_PETS_EVENT_NAME = 'Search Sitter Filter Pets';
export const UPDATE_FILTER_OVERNIGHT_CALENDAR_EVENT_NAME =
  'Search Sitter - Overnight Service - Calendar Update';
export const UPDATE_FILTER_REPEAT_WEEKLY_CALENDAR_EVENT_NAME =
  'Search Sitter - Daytime Service - RWB - Calendar Update';
export const UPDATE_FILTER_ONE_OFF_CALENDAR_EVENT_NAME =
  'Search Sitter - Daytime Service - One Off - Calendar Update';
export const VIEWED_SERVICE_DESCRIPTIONS_EVENT_NAME = 'Viewed service descriptions';
export const VIEWED_HOME_PAGE = 'Viewed Homepage';
export const CLICKED_HOW_IT_WORKS = 'Homepage - CTA - How it works';
export const CLICKED_NAVIGATION_ITEM = 'Navigation Item Click';
export const CLICKED_SERVICE_TAB = 'Homepage - Services Tab Clicked';
export const CLICKED_EXPANDED_SEARCH_DESKTOP = 'Pop up - Sitter Search';
export const CLICKED_EXPANDED_SEARCH_MOBILE = 'Expanded Mobile - Sitter Search';
export const UPDATE_SORT_SITTERS = 'SFS - Sort Sitters';
export const UPDATE_FILTER_SITTERS = 'SFS - Filter Sitters';
export const UPDATE_PRICE_FILTER_SITTERS = 'SFS - Price filter sitters';
export const CLICKED_SITTER_CARD_EVENT_NAME = 'Searched For Sitter - Sitter Clicked';

// Service Landing Page
export const VIEWED_SLP_EVENT_NAME = 'Viewed Service Landing Page';
export const CLICKED_SLP_MOBILE_SEARCH_CTA_EVENT_NAME =
  'Service Landing Page - Mobile - CTA - Trigger Search';
export const UPDATE_SITTER_SEARCH_SLP_MOBILE_EVENT_NAME =
  'Service Landing Page - Mobile - Update Sitter Search  ';
export const CLICKED_PET_PRODUCT_SERVICE = 'Pet Product Click';
export const OTHER_BRAND_CROSS_SELL_CLICK = 'Other Brand Cross-Sell Click';

// Google Auth
export const CLICKED_VERIFY_MOBILE_EVENT_NAME = 'User Verify Mobile Clicked';
export const MOBILE_VERIFIED_EVENT_NAME = 'User Mobile Verified';
export const VIEWED_CONFIRM_NAME_AND_ACCEPT_TERMS_EVENT_NAME =
  'Viewed confirm name and accept terms screen';
export const VIEWED_MOBILE_VERIFICATION_EVENT_NAME = 'Viewed Mobile Verification Screen';
export const VIEWED_TWO_FACTOR_CODE_EVENT_NAME = 'Viewed Two Factor Code Screen';

// Sitter Profile
export const VIEWED_SITTER_PROFILE_EVENT_NAME = 'Viewed Sitter Profile';
export const CLICKED_CONTACT_SITTER_EVENT_NAME = 'Clicked Contact Sitter';
export const CLICKED_FAVOURITE_SITTER_EVENT_NAME = 'Clicked Favourite Sitter';
export const REMOVED_FAVOURITE_SITTER_EVENT_NAME = 'Removed Favourite Sitter';
export const SHARED_SITTER_PROFILE_EVENT_NAME = 'Shared Sitter Profile';
export const CLICKED_EXPAND_SITTER_BIO_EVENT_NAME = 'Clicked Expand Sitter Bio';
export const VIEWED_SITTER_PHOTO_GALLERY_EVENT_NAME = 'Viewed Sitter Photo Gallery';
export const CLICKED_LEARN_ABOUT_ACCIDENT_COVER_EVENT_NAME = 'Clicked Learn About Accident Cover';
export const CLICKED_LEARN_ABOUT_CANCELLATION_EVENT_NAME = 'Clicked Learn About Cancellation';
export const VIEWED_ADDITIONAL_RATES_EVENT_NAME = 'Viewed Additional Rates';
