import {
  IconBookings,
  IconCalendarOutlined,
  IconDashboard,
  IconEnvelopeNav,
  IconFavourite,
  IconPaw,
  IconProfile,
  IconSearch,
  IconStore,
} from '@madpaws/design-system';

import { HELP_CENTRE_LINK } from '~/common/constants/externalLinks';
import {
  HOME_PAGE_SLUG,
  SEARCH_RESULTS_PAGE_SLUG,
  SITTER_PROFILE_PAGE_SLUG,
  ACCOUNT_PAGE_SLUG,
  ACCOUNT_SETTINGS_PAGE_SLUG,
  MOBILE,
  MESSAGES_PAGE_SLUG,
  BOOKINGS_PAGE_SLUG,
  CALENDAR_PAGE_SLUG,
  PETS_PAGE_SLUG,
  DESKTOP,
  PAYMENT_PAGE_SLUG,
  PROFILE_PAGE_SLUG,
  REFERRAL_LANDING_PAGE_SLUG,
  SERVICE_LANDING_PAGE_SLUG,
} from '~/common/constants/pageLayout';
import { handleNavigationItemClickedEvent, SourceType } from '~/components/analytics/utils';

import {
  BECOME_A_SITTER_LINK_LABEL,
  BOOKINGS_LINK_LABEL,
  CALENDER_LINK_LABEL,
  FIND_A_SITTER_LINK_LABEL,
  HELP_LINK_LABEL,
  MESSAGES_LINK_LABEL,
  PETS_LINK_LABEL,
  DASHBOARD_LINK_LABEL,
  FAVOURITES_LINK_LABEL,
  MY_PROFILE_LINK_LABEL,
  ACCOUNT_LINK_LABEL,
  PAYMENT_LINK_LABEL,
  MY_PETS_LINK_LABEL,
  PET_STORE_LINK_LABEL,
  MAD_PAWS_STORE_LINK_LABEL,
  PET_STORE_LINK,
} from './constants';

import type {
  CommonPlatformHeaderIconLink,
  CommonPlatformHeaderMenuLink,
  CommonPlatformHeaderLink,
  CommonPlatformHeaderSignUpLogInLink,
} from '@madpaws/design-system';

export const getLeftNavigationLinks = (
  isUserSitter: boolean,
  route?: string
): CommonPlatformHeaderLink[] | undefined => {
  const navigationLinks = [
    {
      label: PET_STORE_LINK_LABEL,
      href: PET_STORE_LINK,
      icon: <IconStore />,
      onClick: (): void => {
        handleNavigationItemClickedEvent(
          PET_STORE_LINK,
          PET_STORE_LINK_LABEL,
          SourceType.HeaderPrimary,
          isUserSitter
        );
      },
    },
    // Hide Temporary for Testing
    // {
    //   label: REFERRAL_LINK_LABEL,
    //   href: REFERRAL_LANDING_PAGE_SLUG,
    //   icon: <IconPresent />,
    //   onClick: (): void => {
    //     handleNavigationItemClickedEvent(
    //       REFERRAL_LANDING_PAGE_SLUG,
    //       REFERRAL_LINK_LABEL,
    //       SourceType.HeaderPrimary,
    //       isUserSitter
    //     );
    //   },
    // },
  ];

  if (route === SEARCH_RESULTS_PAGE_SLUG) {
    return navigationLinks;
  }

  navigationLinks.unshift({
    label: FIND_A_SITTER_LINK_LABEL,
    href: HOME_PAGE_SLUG,
    icon: <IconSearch />,
    onClick: (): void => {
      handleNavigationItemClickedEvent(
        HOME_PAGE_SLUG,
        FIND_A_SITTER_LINK_LABEL,
        SourceType.HeaderPrimary,
        isUserSitter
      );
    },
  });

  return navigationLinks;
};

export const getSignUpLoginLink = (
  isUserLoggedIn: boolean,
  isUserSitter: boolean,
  link: string,
  name: string,
  sourceType: SourceType
): CommonPlatformHeaderSignUpLogInLink | undefined => {
  if (isUserLoggedIn) {
    return undefined;
  }

  return {
    href: link,
    onClick: (): void => {
      window.location.href = link;
      handleNavigationItemClickedEvent(link, name, sourceType, isUserSitter);
    },
  };
};

export const getRightNavigationLinks = (
  isUserLoggedIn: boolean,
  isUserSitter: boolean
): CommonPlatformHeaderLink[] => {
  if (isUserLoggedIn && isUserSitter) {
    return [
      {
        label: DASHBOARD_LINK_LABEL,
        href: ACCOUNT_PAGE_SLUG,
        onClick: (): void => {
          handleNavigationItemClickedEvent(
            ACCOUNT_PAGE_SLUG,
            DASHBOARD_LINK_LABEL,
            SourceType.HeaderPrimary,
            isUserSitter
          );
        },
      },
    ];
  }

  return [
    {
      label: BECOME_A_SITTER_LINK_LABEL,
      href: '/about/become-a-sitter',
      onClick: (): void => {
        handleNavigationItemClickedEvent(
          '/about/become-a-sitter',
          BECOME_A_SITTER_LINK_LABEL,
          SourceType.HeaderPrimary,
          isUserSitter
        );
      },
    },
  ];
};

export const getIconLinks = (
  isUserLoggedIn: boolean,
  isUserSitter: boolean,
  numberOfUnreadMessages: number | null
): CommonPlatformHeaderIconLink[] => {
  const iconLinks: CommonPlatformHeaderIconLink[] = [
    {
      href: HOME_PAGE_SLUG,
      icon: <IconSearch />,
      label: FIND_A_SITTER_LINK_LABEL,
      viewport: [MOBILE],
      onClick: (): void => {
        handleNavigationItemClickedEvent(
          HOME_PAGE_SLUG,
          FIND_A_SITTER_LINK_LABEL,
          SourceType.HeaderPrimary,
          isUserSitter
        );
      },
    },
  ];

  if (!isUserLoggedIn) {
    return iconLinks;
  }

  iconLinks.push({
    href: MESSAGES_PAGE_SLUG,
    icon: <IconEnvelopeNav />,
    indicatorValue: numberOfUnreadMessages ?? undefined,
    label: MESSAGES_LINK_LABEL,
    viewport: [MOBILE, DESKTOP],
    onClick: (): void => {
      handleNavigationItemClickedEvent(
        MESSAGES_PAGE_SLUG,
        MESSAGES_LINK_LABEL,
        SourceType.HeaderPrimary,
        isUserSitter
      );
    },
  });

  if (isUserSitter) {
    return iconLinks;
  }

  iconLinks.splice(1, 0, {
    href: ACCOUNT_PAGE_SLUG,
    label: FAVOURITES_LINK_LABEL,
    icon: <IconFavourite />,
    viewport: [MOBILE, DESKTOP],
    onClick: (): void => {
      handleNavigationItemClickedEvent(
        ACCOUNT_PAGE_SLUG,
        FAVOURITES_LINK_LABEL,
        SourceType.HeaderPrimary,
        isUserSitter
      );
    },
  });

  return iconLinks;
};

export const getPrimaryMenuLinks = (
  isUserLoggedIn: boolean,
  isUserSitter: boolean,
  numberOfUnreadMessages: number | null,
  sourceType: SourceType
): CommonPlatformHeaderMenuLink[] => {
  if (!isUserLoggedIn) {
    return [];
  }

  const primaryMenuLinks = [
    {
      label: MESSAGES_LINK_LABEL,
      href: MESSAGES_PAGE_SLUG,
      icon: <IconEnvelopeNav />,
      viewport: [MOBILE, DESKTOP],
      indicatorValue: numberOfUnreadMessages ?? undefined,
      onClick: (): void => {
        handleNavigationItemClickedEvent(
          MESSAGES_PAGE_SLUG,
          MESSAGES_LINK_LABEL,
          sourceType,
          isUserSitter
        );
      },
    },
    {
      label: BOOKINGS_LINK_LABEL,
      href: BOOKINGS_PAGE_SLUG,
      icon: <IconBookings />,
      viewport: [MOBILE, DESKTOP],
      onClick: (): void => {
        handleNavigationItemClickedEvent(
          BOOKINGS_PAGE_SLUG,
          BOOKINGS_LINK_LABEL,
          sourceType,
          isUserSitter
        );
      },
    },
  ];

  if (isUserSitter) {
    primaryMenuLinks.unshift({
      label: DASHBOARD_LINK_LABEL,
      href: ACCOUNT_PAGE_SLUG,
      icon: <IconDashboard />,
      viewport: ['mobile'],
      onClick: (): void => {
        handleNavigationItemClickedEvent(
          ACCOUNT_PAGE_SLUG,
          DASHBOARD_LINK_LABEL,
          sourceType,
          isUserSitter
        );
      },
    });
    primaryMenuLinks.push(
      {
        label: CALENDER_LINK_LABEL,
        href: CALENDAR_PAGE_SLUG,
        icon: <IconCalendarOutlined />,
        viewport: [MOBILE, DESKTOP],
        onClick: (): void => {
          handleNavigationItemClickedEvent(
            CALENDAR_PAGE_SLUG,
            CALENDER_LINK_LABEL,
            sourceType,
            isUserSitter
          );
        },
      },
      {
        label: MY_PROFILE_LINK_LABEL,
        href: PROFILE_PAGE_SLUG,
        icon: <IconProfile />,
        viewport: [MOBILE, DESKTOP],
        onClick: (): void => {
          handleNavigationItemClickedEvent(
            PROFILE_PAGE_SLUG,
            MY_PROFILE_LINK_LABEL,
            sourceType,
            isUserSitter
          );
        },
      }
    );

    return primaryMenuLinks;
  }

  primaryMenuLinks.push(
    {
      label: FAVOURITES_LINK_LABEL,
      href: ACCOUNT_PAGE_SLUG,
      icon: <IconFavourite />,
      viewport: [MOBILE, DESKTOP],
      onClick: (): void => {
        handleNavigationItemClickedEvent(
          ACCOUNT_PAGE_SLUG,
          FAVOURITES_LINK_LABEL,
          sourceType,
          isUserSitter
        );
      },
    },
    {
      label: PETS_LINK_LABEL,
      href: PETS_PAGE_SLUG,
      icon: <IconPaw />,
      viewport: [MOBILE, DESKTOP],
      onClick: (): void => {
        handleNavigationItemClickedEvent(
          PETS_PAGE_SLUG,
          MY_PETS_LINK_LABEL,
          sourceType,
          isUserSitter
        );
      },
    }
  );

  return primaryMenuLinks;
};

export const getSecondaryMenuLinks = (
  isUserLoggedIn: boolean,
  isUserSitter: boolean,
  sourceType: SourceType
): CommonPlatformHeaderMenuLink[][] => {
  const helpLink = {
    href: HELP_CENTRE_LINK,
    label: HELP_LINK_LABEL,
    viewport: [MOBILE, DESKTOP],
    onClick: (): void => {
      handleNavigationItemClickedEvent(HELP_CENTRE_LINK, HELP_LINK_LABEL, sourceType, isUserSitter);
    },
  };
  const accountLink = {
    href: ACCOUNT_SETTINGS_PAGE_SLUG,
    label: ACCOUNT_LINK_LABEL,
    viewport: [MOBILE, DESKTOP],
    onClick: (): void => {
      handleNavigationItemClickedEvent(
        ACCOUNT_SETTINGS_PAGE_SLUG,
        ACCOUNT_LINK_LABEL,
        sourceType,
        isUserSitter
      );
    },
  };
  const paymentLink = {
    href: PAYMENT_PAGE_SLUG,
    label: PAYMENT_LINK_LABEL,
    viewport: [MOBILE, DESKTOP],
    onClick: (): void => {
      handleNavigationItemClickedEvent(
        PAYMENT_PAGE_SLUG,
        PAYMENT_LINK_LABEL,
        sourceType,
        isUserSitter
      );
    },
  };
  const becomeASitterLink = {
    label: BECOME_A_SITTER_LINK_LABEL,
    href: '/about/become-a-sitter',
    viewport: [MOBILE],
    onClick: (): void => {
      handleNavigationItemClickedEvent(
        '/about/become-a-sitter',
        BECOME_A_SITTER_LINK_LABEL,
        sourceType,
        isUserSitter
      );
    },
  };
  const storeLink = {
    label: MAD_PAWS_STORE_LINK_LABEL,
    href: PET_STORE_LINK,
    viewport: [MOBILE],
    onClick: (): void => {
      handleNavigationItemClickedEvent(
        PET_STORE_LINK,
        MAD_PAWS_STORE_LINK_LABEL,
        sourceType,
        isUserSitter
      );
    },
  };
  // const referralLink = {
  //   label: REFERRAL_LINK_LABEL,
  //   href: REFERRAL_LANDING_PAGE_SLUG,
  //   viewport: [MOBILE],
  //   onClick: (): void => {
  //     handleNavigationItemClickedEvent(
  //       REFERRAL_LANDING_PAGE_SLUG,
  //       REFERRAL_LINK_LABEL,
  //       sourceType,
  //       isUserSitter
  //     );
  //   },
  // };

  if (!isUserLoggedIn) {
    return [[becomeASitterLink, storeLink, helpLink]];
  }

  if (isUserSitter) {
    // return [
    //   [referralLink, storeLink],
    //   [paymentLink, helpLink, accountLink],
    // ];
    return [[storeLink], [paymentLink, helpLink, accountLink]];
  }

  // return [
  //   [becomeASitterLink, referralLink, storeLink],
  //   [helpLink, accountLink],
  // ];
  return [
    [becomeASitterLink, storeLink],
    [helpLink, accountLink],
  ];
};

const pagesWithoutSecondaryNavigation = [
  HOME_PAGE_SLUG,
  SERVICE_LANDING_PAGE_SLUG,
  SEARCH_RESULTS_PAGE_SLUG,
  SITTER_PROFILE_PAGE_SLUG,
  REFERRAL_LANDING_PAGE_SLUG,
];

export const getSecondaryNavigationLinks = (
  isUserLoggedIn: boolean,
  isUserSitter: boolean,
  pathname: string
): CommonPlatformHeaderLink[] => {
  if (!isUserLoggedIn || pagesWithoutSecondaryNavigation.includes(pathname)) {
    return [];
  }

  const secondaryNavigationLinks = [
    {
      label: MESSAGES_LINK_LABEL,
      href: MESSAGES_PAGE_SLUG,
      isActive: MESSAGES_PAGE_SLUG === pathname,
      onClick: (): void => {
        handleNavigationItemClickedEvent(
          MESSAGES_PAGE_SLUG,
          MESSAGES_LINK_LABEL,
          SourceType.HeaderSecondary,
          isUserSitter
        );
      },
    },
    {
      label: BOOKINGS_LINK_LABEL,
      href: BOOKINGS_PAGE_SLUG,
      isActive: BOOKINGS_PAGE_SLUG === pathname,
      onClick: (): void => {
        handleNavigationItemClickedEvent(
          BOOKINGS_PAGE_SLUG,
          BOOKINGS_LINK_LABEL,
          SourceType.HeaderSecondary,
          isUserSitter
        );
      },
    },
    {
      label: ACCOUNT_LINK_LABEL,
      href: ACCOUNT_SETTINGS_PAGE_SLUG,
      isActive: ACCOUNT_SETTINGS_PAGE_SLUG === pathname,
      onClick: (): void => {
        handleNavigationItemClickedEvent(
          ACCOUNT_SETTINGS_PAGE_SLUG,
          ACCOUNT_LINK_LABEL,
          SourceType.HeaderSecondary,
          isUserSitter
        );
      },
    },
  ];

  if (isUserSitter) {
    secondaryNavigationLinks.unshift({
      label: DASHBOARD_LINK_LABEL,
      href: ACCOUNT_PAGE_SLUG,
      isActive: ACCOUNT_PAGE_SLUG === pathname,
      onClick: (): void => {
        handleNavigationItemClickedEvent(
          ACCOUNT_PAGE_SLUG,
          DASHBOARD_LINK_LABEL,
          SourceType.HeaderSecondary,
          isUserSitter
        );
      },
    });
    secondaryNavigationLinks.splice(
      3,
      0,
      {
        label: CALENDER_LINK_LABEL,
        href: CALENDAR_PAGE_SLUG,
        isActive: CALENDAR_PAGE_SLUG === pathname,
        onClick: (): void => {
          handleNavigationItemClickedEvent(
            CALENDAR_PAGE_SLUG,
            CALENDER_LINK_LABEL,
            SourceType.HeaderSecondary,
            isUserSitter
          );
        },
      },
      {
        label: MY_PROFILE_LINK_LABEL,
        href: PROFILE_PAGE_SLUG,
        isActive: PROFILE_PAGE_SLUG === pathname,
        onClick: (): void => {
          handleNavigationItemClickedEvent(
            PROFILE_PAGE_SLUG,
            MY_PROFILE_LINK_LABEL,
            SourceType.HeaderSecondary,
            isUserSitter
          );
        },
      },
      {
        label: PAYMENT_LINK_LABEL,
        href: PAYMENT_PAGE_SLUG,
        isActive: PAYMENT_PAGE_SLUG === pathname,
        onClick: (): void => {
          handleNavigationItemClickedEvent(
            PAYMENT_PAGE_SLUG,
            PAYMENT_LINK_LABEL,
            SourceType.HeaderSecondary,
            isUserSitter
          );
        },
      }
    );

    return secondaryNavigationLinks;
  }

  secondaryNavigationLinks.splice(
    2,
    0,
    {
      label: FAVOURITES_LINK_LABEL,
      href: ACCOUNT_PAGE_SLUG,
      isActive: ACCOUNT_PAGE_SLUG === pathname,
      onClick: (): void => {
        handleNavigationItemClickedEvent(
          ACCOUNT_PAGE_SLUG,
          FAVOURITES_LINK_LABEL,
          SourceType.HeaderSecondary,
          isUserSitter
        );
      },
    },
    {
      label: MY_PETS_LINK_LABEL,
      href: PETS_PAGE_SLUG,
      isActive: PETS_PAGE_SLUG === pathname,
      onClick: (): void => {
        handleNavigationItemClickedEvent(
          PETS_PAGE_SLUG,
          MY_PETS_LINK_LABEL,
          SourceType.HeaderSecondary,
          isUserSitter
        );
      },
    }
  );

  return secondaryNavigationLinks;
};
