import { ApolloProvider } from '@apollo/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'reflect-metadata';
import NextApp from 'next/app';
import { useRouter } from 'next/router';

import { client } from '~/api/graphql/client';
import { BrainfishButton } from '~/components/Brainfish/Brainfish';
import { FeatureFlagsProvider } from '~/components/FeatureFlags/FeatureFlagsProvder';
import { getFeatureFlags } from '~/components/FeatureFlags/utils';
import { GoogleOneTapLoginProvider } from '~/components/GoogleOAuthProvider/GoogleOneTapLoginProvider';
import { Layout } from '~/components/Layout/Layout';
import { ResponsiveContextProvider } from '~/components/ResponsiveContextProvider/ResponsiveContextProvider';
import { SplitIOProvider } from '~/components/SplitIO/SplitIOProvider';
import { UserLoggedInProvider } from '~/components/UserAuthProvider/UserAuthProvider';
import { UserDetailsProvider } from '~/components/UserDetailsProvider/UserDetailsProvider';
import { AnalyticsInitiator } from '~/components/analytics/AnalyticsInitiator/AnalyticsInitiator';
import { AnalyticsPageTracker } from '~/components/analytics/AnalyticsPageTracker/AnalyticsPageTracker';
import { shouldShowChatWidget } from '~/utils/chatWidget';
import { inferScreenWidthFromUserAgent } from '~/utils/userAgent';

import type { NextPageContext } from 'next';
import type { AppContext, AppInitialProps, AppProps } from 'next/app';
import type { ReactElement } from 'react';
import type { FeatureFlag } from '~/common/types/settings';

import '@madpaws/design-system/dist/global.css';
import '@madpaws/design-system/dist/components.css';

type CustomProps = {
  featureFlags: FeatureFlag[];
  inferredScreenWidth: number;
};

type Props = AppProps & CustomProps;

const App = ({ Component, pageProps, featureFlags, inferredScreenWidth }: Props): ReactElement => {
  const router = useRouter();

  return (
    <ApolloProvider client={client}>
      <ResponsiveContextProvider inferredScreenWidth={inferredScreenWidth}>
        <FeatureFlagsProvider featureFlags={featureFlags}>
          <AnalyticsInitiator>
            <AnalyticsPageTracker>
              <UserLoggedInProvider>
                <SplitIOProvider>
                  <GoogleOAuthProvider
                    clientId={process.env.NEXT_PUBLIC_GOOGLE_SDK_CLIENT_ID || ''}
                  >
                    <GoogleOneTapLoginProvider route={router.pathname}>
                      <UserDetailsProvider>
                        <Layout from={router.query.from} route={router.pathname}>
                          <Component {...pageProps} />
                          {shouldShowChatWidget(
                            inferredScreenWidth,
                            router.pathname,
                            router.query.from
                          ) && <BrainfishButton />}
                        </Layout>
                      </UserDetailsProvider>
                    </GoogleOneTapLoginProvider>
                  </GoogleOAuthProvider>
                </SplitIOProvider>
              </UserLoggedInProvider>
            </AnalyticsPageTracker>
          </AnalyticsInitiator>
        </FeatureFlagsProvider>
      </ResponsiveContextProvider>
    </ApolloProvider>
  );
};

type InitialProps = AppInitialProps & CustomProps;

App.getInitialProps = async (appContext: AppContext & NextPageContext): Promise<InitialProps> => {
  const appProps = await NextApp.getInitialProps(appContext);
  const inferredScreenWidth = inferScreenWidthFromUserAgent(appContext.ctx.req);
  const featureFlags = await getFeatureFlags();

  return { ...appProps, featureFlags, inferredScreenWidth };
};

export default App;
