import { createContext, useContext, useState } from 'react';

import type { ReactElement } from 'react';

export type Context = {
  isUserDetailsLoading: boolean;
  isUserSitter: boolean;
  setIsUserDetailsLoading(isLoading: boolean): void;
  setIsUserSitter(isSitter: boolean): void;
};

// export for testing purpose
export const UserDetailsContext = createContext<Context>({
  isUserDetailsLoading: true,
  isUserSitter: false,
  setIsUserDetailsLoading: () => {},
  setIsUserSitter: () => {},
});

export const useUserDetailsContext = (): Context => useContext(UserDetailsContext);

type Props = {
  children: ReactElement | ReactElement[];
};

export const UserDetailsProvider = ({ children }: Props): ReactElement => {
  const [isUserDetailsLoading, setIsUserDetailsLoading] = useState<boolean>(true);
  const [isUserSitter, setIsUserSitter] = useState<boolean>(false);

  return (
    <UserDetailsContext.Provider
      value={{ isUserDetailsLoading, setIsUserDetailsLoading, isUserSitter, setIsUserSitter }}
    >
      {children}
    </UserDetailsContext.Provider>
  );
};
