import { useMutation, useLazyQuery } from '@apollo/client';
import {
  CommonPlatformHeader as DesignSystemPlatformHeader,
  useMediumFromMediaQuery,
} from '@madpaws/design-system';
import Cookies from 'js-cookie';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import {
  ACCESS_TOKEN_COOKIE_NAME,
  MD_AUTH_LOCAL_STORAGE_KEY,
  SIGN_IN_ROUTE,
  SIGN_UP_ROUTE,
} from '~/common/constants/auth';
import { transformProfileUrl } from '~/common/utils/sitters';
import { useUserLoggedInContext } from '~/components/UserAuthProvider/UserAuthProvider';
import { useUserDetailsContext } from '~/components/UserDetailsProvider/UserDetailsProvider';
import { SourceType, handleNavigationItemClickedEvent } from '~/components/analytics/utils';
import { DOM_CONTAINER_ID } from '~/components/constants';
import { removeLocalStorage } from '~/utils/localStorage';

import { GET_USER_INFO, LOG_OUT_USER } from './queries';
import {
  getLeftNavigationLinks,
  getIconLinks,
  getRightNavigationLinks,
  getPrimaryMenuLinks,
  getSecondaryMenuLinks,
  getSecondaryNavigationLinks,
  getSignUpLoginLink,
} from './utils';

import type { CommonPlatformHeaderUserDetails } from '@madpaws/design-system';
import type { ReactElement } from 'react';
import type { UserDetails } from '~/common/types/user';

const { publicRuntimeConfig } = getConfig();

const PlatformHeader = (): ReactElement => {
  const router = useRouter();
  const isMediumFromViewport = useMediumFromMediaQuery();
  const [numberOfUnreadMessages, setNumberOfUnreadMessages] = useState<number | null>(null);
  const [userDetails, setUserDetails] = useState<CommonPlatformHeaderUserDetails | undefined>(
    undefined
  );
  const { isUserLoggedIn, setIsUserLoggedIn } = useUserLoggedInContext();
  const { isUserSitter, setIsUserSitter, setIsUserDetailsLoading } = useUserDetailsContext();

  const [fetchUserInfo] = useLazyQuery<{
    numberOfUnreadMessages: number | null;
    userDetails: UserDetails | null;
  }>(GET_USER_INFO, {
    onCompleted: ({
      numberOfUnreadMessages: fetchedNumberOfUnreadMessages,
      userDetails: fetchedUserDetails,
    }) => {
      // update loading state right after query is executed
      // as fetchedUserDetails can be null for not logged in user
      setIsUserDetailsLoading(false);

      if (fetchedUserDetails) {
        const { isSitter, avatar, fullName, city, state, rewriteUrl } = fetchedUserDetails;
        const sitterUrl = transformProfileUrl(city, rewriteUrl, state);

        setIsUserSitter(isSitter ?? false);
        setUserDetails({
          avatar: avatar,
          name: fullName,
          sitterUrl: isSitter ? sitterUrl : undefined,
          onClick: () => {
            handleNavigationItemClickedEvent(
              sitterUrl,
              'View public profile',
              isMediumFromViewport
                ? SourceType.HamburgerMenuDesktop
                : SourceType.HamburgerMenuMobile,
              isSitter
            );
          },
        });
      }

      if (fetchedNumberOfUnreadMessages) {
        setNumberOfUnreadMessages(fetchedNumberOfUnreadMessages);
      }
    },
    fetchPolicy: 'cache-and-network',
  });

  const [logOutUser] = useMutation<{ logOut: boolean }>(LOG_OUT_USER, {
    onCompleted: ({ logOut: loggedOut }) => {
      if (loggedOut) {
        removeLocalStorage(MD_AUTH_LOCAL_STORAGE_KEY);
        Cookies.remove(ACCESS_TOKEN_COOKIE_NAME);
        setIsUserLoggedIn(false);
        window.location.reload();
      }
    },
  });

  const onLogOutClick = (): void => {
    logOutUser();
    handleNavigationItemClickedEvent(
      '',
      'Log out',
      isMediumFromViewport ? SourceType.HamburgerMenuDesktop : SourceType.HamburgerMenuMobile,
      isUserSitter
    );
  };

  useEffect(() => {
    setIsUserDetailsLoading(true);
    fetchUserInfo();
  }, [fetchUserInfo, setIsUserDetailsLoading]);

  const iconLinks = getIconLinks(isUserLoggedIn ?? false, isUserSitter, numberOfUnreadMessages);
  const leftNavigationLinks = getLeftNavigationLinks(isUserSitter, router.pathname);
  const rightNavigationLinks = getRightNavigationLinks(isUserLoggedIn ?? false, isUserSitter);
  const primaryMenuLinks = getPrimaryMenuLinks(
    isUserLoggedIn ?? false,
    isUserSitter,
    numberOfUnreadMessages,
    isMediumFromViewport ? SourceType.HamburgerMenuDesktop : SourceType.HamburgerMenuMobile
  );
  const secondaryMenuLinks = getSecondaryMenuLinks(
    isUserLoggedIn ?? false,
    isUserSitter,
    isMediumFromViewport ? SourceType.HamburgerMenuDesktop : SourceType.HamburgerMenuMobile
  );
  const secondaryNavigationLinks = getSecondaryNavigationLinks(
    isUserLoggedIn ?? false,
    isUserSitter,
    router.pathname
  );
  const logInLink = getSignUpLoginLink(
    isUserLoggedIn ?? false,
    isUserSitter,
    SIGN_IN_ROUTE,
    'Log in',
    isMediumFromViewport ? SourceType.HeaderPrimary : SourceType.HamburgerMenuMobile
  );
  const signUpLink = getSignUpLoginLink(
    isUserLoggedIn ?? false,
    isUserSitter,
    SIGN_UP_ROUTE,
    'Sign up',
    isMediumFromViewport ? SourceType.HeaderPrimary : SourceType.HamburgerMenuMobile
  );

  return (
    <DesignSystemPlatformHeader
      domContainerId={DOM_CONTAINER_ID}
      iconLinks={iconLinks}
      leftNavigationLinks={leftNavigationLinks}
      logInLink={logInLink}
      logoFolderPath={`${publicRuntimeConfig.staticPath}/images`}
      onLogOutClick={onLogOutClick}
      primaryMenuLinks={primaryMenuLinks}
      rightNavigationLinks={rightNavigationLinks}
      secondaryMenuLinks={secondaryMenuLinks}
      secondaryNavigationLinks={secondaryNavigationLinks}
      signUpLink={signUpLink}
      userDetails={userDetails}
    />
  );
};

export { PlatformHeader };
